<template src="./BlogArticles.html"></template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import categoryService from "@/services/Category";
import { required } from "vuelidate/lib/validators";
import { putFile } from "@/services/files";
import newsService from "../../services/News";
import ModalDescriptionFile from "../../components/ModalDescriptionFile/ModalDescriptionFile";
import idiService from "@/services/Idi";

export default {
  name: "blogArticles",
  components: {
    ModalDescriptionFile,
  },
  data() {
    return {
      id: null,
      success: false,
      news: {
        name: "",
        categoryId: "",
        description: "",
        image: "",
        institutionId: "",
      },
      categories: [],
      institutions: [],
      myInstitutions: [],
      editor: ClassicEditor,
      editorConfig: {
        language: {
          // The UI will be in spanish.
          ui: "es",
          // But the content will be edited in spanish.
          content: "es",
        },
        alignment: {
          options: ["left", "right"],
        },
      },
      modalDeleteNews: false,
      update: false,
      showModalImageDescription: false,
      role: "",
      imageUrl: null,
    };
  },
  validations: {
    news: {
      name: { required },
      categoryId: { required },
      description: { required },
      institutionId: { required },
    },
  },
  created() {
    this.getInfo();
    if (this.$route.params.id) {
      this.getInfoEditNews();
    }
    this.role = this.$store.state.User.user.role.name;
  },
  methods: {
    async getInfo() {
      try {
        this.categories = await categoryService.getCategories();
        this.institutions = await idiService.listInstitutions();
        this.myInstitutions = await idiService.listMyInstitutions();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: blogArticles/getInfo()", e);
      }
    },
    async getInfoEditNews() {
      try {
        const responseNew = await newsService.getSpecificNews(
          this.$route.params.id
        );
        this.update = true;
        this.news.name = responseNew.name;
        this.news.institutionId = responseNew.institution.id;
        this.news.categoryId = responseNew.category.id;
        this.news.description = responseNew.description;
        this.news.image = responseNew.image;
        this.id = responseNew.id;
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: blogArticles/getInfo()", e);
      }
    },
    async saveNews() {
      if (this.imageUrl != null) {
        try {
          this.$store.dispatch("fetchLoading", true);
          const urlImage = await putFile(
            this.imageUrl,
            "News",
            `${this.imageUrl.name}`
          );
          this.news.image = urlImage;
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo",
            position: "is-bottom",
            type: "is-danger",
          });
        }
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta una imagen",
          position: "is-bottom",
          type: "is-danger",
        });
        return null;
      }
      try {
        this.$store.dispatch("fetchLoading", true);
        const response = await newsService.createNews(this.news);
        this.news.id = response.id;
        this.$store.dispatch("fetchLoading", false);
        this.success = true;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Noticia creada",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: blogArticles/saveNews()", e);
      }
    },
    deleteNews() {
      try {
        this.modalDeleteNews = true;
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: blogArticles/deleteNews()", e);
      }
    },
    async removeNews() {
      try {
        await newsService.deleteNews(this.id);
        this.modalDeleteNews = false;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Noticia eliminada",
          position: "is-bottom",
          type: "is-success",
        });
        this.$router.push({ path: "idi" }).catch(() => { });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: blogArticles/removeNews()", e);
      }
    },
    ShowEditNews() {
      this.success = false;
      this.update = true;
    },
    async editNews() {
      if (this.imageUrl != null) {
        try {
          this.$store.dispatch("fetchLoading", true);
          const urlImage = await putFile(
            this.imageUrl,
            "News",
            `${this.imageUrl.name}`
          );
          this.news.image = urlImage;
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo",
            position: "is-bottom",
            type: "is-danger",
          });
        }
      }
      try {
        this.success = false;
        this.update = true;
        this.$store.dispatch("fetchLoading", true);
        await newsService.updateNews(this.id, this.news);
        this.$router.push({ name: "DetailNews", params: { id: this.id } });
        this.$store.dispatch("fetchLoading", false);
        this.success = true;
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se editó la noticia con éxito",
          type: "is-success",
        });
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    deleteFile() {
      this.imageUrl = null;
    },
    openModalImageDescription() {
      this.showModalImageDescription = true;
    },
    closeModalDescriptionImage() {
      this.showModalImageDescription = false;
    },
    uploadFileInfo(info) {
      this.news.descriptionImg = info.description;
      this.imageUrl = info.file;
      this.showModalImageDescription = false;
    },
  },
  computed: {
    roleValidationAdmin() {
      return ["AdministratorCall", "Administrator"].some(
        (a) => a === this.role
      );
    },
  },
};
</script>

<style lang="scss" scoped src="./BlogArticles.scss">

</style>